//authentication
export const AUTH_USER_LOGIN_START = 'AUTH_USER_LOGIN_START';
export const AUTH_USER_LOGIN_SUCCESS = 'AUTH_USER_LOGIN_SUCCESS';
export const AUTH_USER_LOGIN_FAILED = 'AUTH_USER_LOGIN_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const LOGOUT_INIT = 'LOGOUT_INIT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const AUTO_AUTH_FALED = 'AUTO_AUTH_FALED';   
export const AUTH_USER_LOGIN_INIT = 'AUTH_USER_LOGIN_INIT'
export const AUTO_AUTH_INIT = 'AUTO_AUTH_INIT';
export const AUTH_USER_SIGNUP_INIT = 'AUTH_USER_SIGNUP_INIT';
export const AUTH_USER_SIGNUP_START = 'AUTH_USER_SIGNUP_START';
export const AUTH_USER_SIGNUP_FAILED = 'AUTH_USER_SIGNUP_FAILED';
export const AUTH_USER_SIGNUP_SUCCESS = 'AUTH_USER_SIGNUP_SUCCESS';
export const AUTO_AUTH_SUCCESS = 'AUTO_AUTH_SUCCESS';
export const AUTO_AUTH_FAILED = 'AUTO_AUTH_FAILED';
export const AUTO_AUTO_ABORTED = 'AUTO_AUTO_ABORTED';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const ACCOUNT_RECOVERY_START = 'ACCOUNT_RECOVERY_START';
export const ACCOUNT_RECOVERY_FAILED = 'ACCOUNT_RECOVERY_FAILED';
export const ACCOUNT_RECOVERY_SUCCESS = 'ACCOUNT_RECOVERY_SUCCESS';
export const ACCOUNT_PASSWORD_CHANGE_INIT = 'ACCOUNT_PASSWORD_CHANGE_INIT'
export const ACCOUNT_PASSWORD_CHANGE_SUCCESS = 'ACCOUNT_PASSWORD_CHANGE_SUCCESS'
export const ACCOUNT_PASSWORD_CHANGE_FAILED = 'ACCOUNT_PASSWORD_CHANGE_FAILED';
export const ACCOUNT_PASSWORD_CHANGE_START = 'ACCOUNT_PASSWORD_CHANGE_START';

export const CLASSROOM_CREATE_INIT = 'CLASSROOM_CREATE_INIT'
export const CLASSROOM_CREATE_START = 'CLASSROOM_CREATE_START'
export const CLASS_CREATION_FAILED = 'CLASS_CREATION_FAILED'
export const CLASS_CREATION_SUCCESS = 'CLASS_CREATION_SUCCESS'
export const CLASSROOM_JOINED = 'CLASSROOM_JOINED'
export const CLASSROOM_LEFT = 'CLASSROOM_LEFT'
export const ENTERED_CLASS = 'ENTERED_CLASS'
export const CLASSROOM_VERIFICATION_INIT = 'CLASSROOM_VERIFICATION_INIT'
export const CLASSROOM_VERIFY_FAILED_NETWORK = 'CLASSROOM_VERIFY_FAILED_NETWORK'
export const CLASSROOM_VERIFICATION_FAILED = 'CLASSROOM_VERIFICATION_FAILED'
export const CLASSROOM_VERIFICATION_SUCCESS = 'CLASSROOM_VERIFIED_SUCCESS'
export const CLASSROOM_ASYNC_VERIFICATION_INIT  = 'CLASSROOM_ASYNC_VERIFICATION_INIT'
export const CLASSROOM_RESET = 'CLASSROOM_RESET';
export const SET_DEFAULT_INPUT_OUTPUT_DEVICES = 'SET_DEFAULT_INPUT_OUTPUT_DEVICES';
export const SET_INPUT_OUTPUT_DEVICES = 'SET_INPUT_OUTPUT_DEVICES';
export const SET_MESSAGE_THREAD = 'SET_MESSAGE_THREAD';
export const MESSAGE_THREAD_FETCH_START = 'MESSAGE_THREAD_FETCH_START';
export const MESSAGE_THREAD_DATA_SET_DONE = 'MESSAGE_THREAD_DATA_SET_DONE';
export const MESSAGE_THREAD_FETCH_DONE = 'MESSAGE_THREAD_FETCH_DONE';
export const MESSAGE_THREAD_UPDATED = 'MESSAGE_THREAD_UPDATED'
export const SET_DISPLAYING_MESSAGE_REACTION_PICKER = 'SET_DISPLAYING_MESSAGE_REACTION_PICKER'
export const SET_CLASSROOM_SOCKET_CONNECTION = 'SET_CLASSROOM_SOCKET_CONNECTION';
export const CLOSE_MESSAGE_REACTION_EMOJI_PICKER =
    'CLOSE_MESSAGE_REACTION_EMOJI_PICKER';
export const SET_EDIT_OR_DELETE_MESSAGE_DATA = 'SET_EDIT_OR_DELETE_MESSAGE_DATA'
export const UNSET_EDIT_OR_DELETE_MESSAGE_DATA = 'UNSET_EDIT_OR_DELETE_MESSAGE_DATA'
export const CLASSROOM_INFO_UPDATE = 'CLASSROOM_INFO_UPDATE';
export const UPDATE_ROOM_GRAVATAR = 'UPDATE_ROOM_GRAVATAR';

//cookies
export const ACCEPT_COOKIE = 'ACCEPT_COOKIE';
export const COOKIE_ALREADY_ACCEPTED = 'COOKIE_ALREADY_ACCEPTED';
export const COOKIE_VALIDATE_INIT = 'COOKIE_VALIDATE_INIT';

export const ENVIRONMENT_SWITCH  =  'ENVIRONMENT_SWITCH';

export const NOTIFICATION_ALERT_CLOSE = 'NOTIFICATION_ALERT_CLOSE';
export const AUTH_RESET = 'AUTH_RESET';